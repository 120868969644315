html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

a { 
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-family: 'MontserratLight';
  src: url('./fonts/MontserratAlt1-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratExtraLight';
  src: url('./fonts/MontserratAlt1-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratMedium';
  src: url('./fonts/MontserratAlt1-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratRegular';
  src: url('./fonts/MontserratAlt1-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratThin';
  src: url('./fonts/MontserratAlt1-Thin.ttf') format('truetype');
}